<template>
  <b-form class="bg-black-0 rounded p-sm-32 mt-3">
    <h2>Diisi oleh Petugas</h2>
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-4"
          label="Validasi Donor"
        >
          <b-form-radio
            v-for="option in updateAdm.validation"
            :key="option.value"
            v-model="updateAdm.selectedValidation"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-radio>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Riwayat Donor Sebelumnya"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-group-1"
            v-model="updateAdm.prev_history"
            rows="3"
            max-rows="6"
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Donor Terakhir Tanggal"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="updateAdm.last_donor_date"
            class="mb-3"
            type="date"
            placeholder="Masukkan Tanggal Terakhir Donor"
            required
          />
          <label>Sekarang donor yang ke</label>
          <b-form-input
            id="input-2"
            v-model="updateAdm.donor_times"
            placeholder="Enter name"
            type="number"
            required
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-group-4"
          label="Macam Donor"
        >
          <b-form-radio
            v-for="option in updateAdm.donor_type"
            :key="option.value"
            v-model="updateAdm.selectedDonorType"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-radio>
          <label class="mt-3">OS</label>
          <b-form-input
            id="input-2"
            v-model="updateAdm.os"
            class="mb-3"
            placeholder="Masukkan OS"
            required
          />

          <label>RS</label>
          <b-form-input
            id="input-2"
            v-model="updateAdm.rs"
            class="mb-3"
            placeholder="Masukkan RS"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div
      class="d-flex justify-content-end pl-16 pl-sm-32 overflow-hidden mb-10"
    >
      <b-button
        variant="danger"
        class="mx-3"
        size="md"
        :disabled="acceptOrRejectionSuccess"
        @click="$emit('open-confirmation-modal-reject')"
      >Rejected</b-button>
      <b-button
        variant="primary"
        class="mx-3"
        size="md"
        :disabled="acceptOrRejectionSuccess"
        @click="$emit('open-confirmation-modal-accept')"
      >Terima</b-button>
    </div>
  </b-form>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'FormRegisterExamination',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
  },
  props: ['updateAdm', 'acceptOrRejectionSuccess'],
}
</script>
