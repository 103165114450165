<template>
  <div>
    <b-card>
      <progress-bar-status :progress="0" :status="currentDataPendonor.status" />
    </b-card>

    <table-doctor-questionnaire
      :registration_id="currentDataPendonor.registration_id"
      :pendonorName="currentDataPendonor.name"
      :editable="true"
      questionnaireType="update"
      :checkboxDisabled="false"
      mode="admin"
    />

    <div>
      <b-form class="bg-black-0 rounded p-sm-32 mt-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h2>Diisi oleh Petugas</h2>
          <b-button
            v-if="currentDataPendonor.status !== 'Waiting'"
            variant="primary"
            @click="toggleEditMode"
          >
            {{ isEditMode ? "Batal" : "Edit" }}
          </b-button>
        </div>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              id="input-group-4"
              label="No.KTP/SIM/Paspor"
              label-for="input-group-4"
            >
              <b-form-radio-group
                v-model="updateAdm.selectedValidation"
                :disabled="isFormDisabled"
              >
                <b-form-radio
                  v-for="option in validation"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Riwayat Donor Sebelumnya"
              label-for="input-1"
            >
              <b-form-textarea
                id="input-1"
                v-model="updateAdm.prev_history"
                rows="3"
                max-rows="6"
                :disabled="isFormDisabled"
              />
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Donor Terakhir Tanggal"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="updateAdm.last_donor_date"
                type="date"
                placeholder="Masukkan Tanggal Terakhir Donor"
                required
                :disabled="isFormDisabled"
              />
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Sekarang donor yang ke"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="updateAdm.donor_times"
                placeholder="Masukkan jumlah donor"
                type="number"
                required
                :disabled="isFormDisabled"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              id="input-group-9"
              label="Macam Donor"
              label-for="input-group-9"
            >
              <b-form-radio-group
                v-model="updateAdm.selectedDonorType"
                :disabled="isFormDisabled"
              >
                <b-form-radio
                  id="input-group-9"
                  v-for="option in donor_type"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group id="input-group-5" label="OS" label-for="input-5">
              <b-form-input
                id="input-5"
                v-model="updateAdm.os"
                placeholder="Masukkan OS"
                required
                :disabled="isOSRSDisabled"
              />
            </b-form-group>
            <b-form-group id="input-group-6" label="RS" label-for="input-6">
              <b-form-input
                id="input-6"
                v-model="updateAdm.rs"
                placeholder="Masukkan RS"
                required
                :disabled="isOSRSDisabled"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div
          class="d-flex justify-content-end pl-16 pl-sm-32 overflow-hidden mb-10"
        >
          <b-button
            v-if="currentDataPendonor.status === 'Waiting' || isEditMode"
            variant="danger"
            class="mx-3"
            size="md"
            :disabled="acceptOrRejectionSuccess"
            @click="openConfirmationModal('reject')"
          >
            Rejected
          </b-button>
          <b-button
            v-if="currentDataPendonor.status === 'Waiting' || isEditMode"
            variant="primary"
            class="mx-3"
            size="md"
            :disabled="
              acceptOrRejectionSuccess ||
              !isFormValid ||
              (updateAdm.selectedDonorType === 'pengganti' &&
                (!updateAdm.os || !updateAdm.rs))
            "
            @click="openConfirmationModal('accept')"
          >
            {{ isEditMode ? "Simpan" : "Terima" }}
          </b-button>
        </div>
      </b-form>
    </div>

    <b-modal id="modal" v-model="showModal" centered hide-header hide-footer>
      <template>
        <h5 v-if="modalType === 'accept'" class="mb-0">
          Konfirmasi Approved Status
        </h5>
        <h5 v-else-if="modalType == 'reject'" class="mb-0">
          Konfirmasi Rejected Status
        </h5>
      </template>

      <p v-if="modalType === 'accept'">
        Apakah anda akan menyetujui Pendonor Atas Nama
        <strong>{{ currentDataPendonor.name }}</strong
        >?
      </p>
      <div v-else-if="modalType == 'reject'">
        <span>Apakah anda akan menolak Pendonor Atas Nama </span>
        <strong>{{ currentDataPendonor.name }}</strong
        >?
        <div class="mt-3">
          <b-form-group label="Alasan:" label-for="rejection-reason">
            <b-form-input
              id="rejection-reason"
              :disabled="updateAdm.rejectionReason === 'lain-lain'"
              v-model="updateAdm.rejectionReason"
              type="text"
              placeholder="Masukkan Alasan"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-checkbox
            v-model="updateAdm.rejectionReason"
            name="some-radios"
            value="lain-lain"
          >
            Lain-lain
          </b-form-checkbox> -->
        </div>
        <div class="border-1 mt-3"></div>
        <b-form-group label="Catatan" label-for="textarea-no-resize">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="updateAdm.note"
            placeholder="Masukkan Catatan"
            rows="3"
            no-resize
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button variant="text" @click="showModal = false"> Tutup</b-button>
        <b-button
          class="ml-16"
          :disabled="isLoading"
          :variant="modalType === 'accept' ? 'primary' : 'danger'"
          @click="handleAction"
        >
          {{
            modalType === "accept"
              ? "Ya, Terima"
              : modalType === "reject"
              ? "Ya, Reject"
              : "Ya, Edit"
          }}
          <b-spinner small label="Loading" v-if="isLoading"></b-spinner>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BModal,
  BRow,
  BCol,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import ProgressBarStatus from "@/view/main/dashboards/registrationdonation/components/common/ProgressBarStatus.vue";
import AktivasiPendonorService from "../../../../api/managependonor/managePendonorAPI";
import FormRegisterExamination from "./components/common/Register/FormRegisterExamination.vue";
import TableDoctorQuestionnaire from "./components/common/docter/ViewDoctorQuestionnaire.vue";

export default {
  name: "ViewDonationStatus",
  components: {
    ProgressBarStatus,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    FormRegisterExamination,
    TableDoctorQuestionnaire,
  },
  data() {
    return {
      currentDataPendonor: null,
      acceptOrRejectionSuccess: false,
      showModal: false,
      modalType: "",
      isEditMode: false,
      isLoading: false,
      updateAdm: {
        id: "",
        selectedValidation: "",
        prev_history: "",
        last_donor_date: "",
        selectedDonorType: "",
        donor_times: "",
        os: "",
        rs: "",
        rejectionReason: "",
        note: "",
      },
      validation: [
        { text: "KTP", value: "ktp" },
        { text: "Kartu Donor", value: "kartu donor" },
        { text: "SIM", value: "sim" },
        { text: "PASPOR", value: "paspor" },
      ],
      donor_type: [
        { text: "Sukarela", value: "sukarela" },
        { text: "Pengganti", value: "pengganti" },
      ],
    };
  },
  mounted() {
    this.getPendonor(this.$route.params.id);
  },
  computed: {
    isFormValid() {
      return (
        this.updateAdm.selectedValidation &&
        this.updateAdm.selectedDonorType &&
        this.updateAdm.donor_times
      );
    },
    isFormDisabled() {
      return this.currentDataPendonor.status !== "Waiting" && !this.isEditMode;
    },
    isOSRSDisabled() {
      return this.updateAdm.selectedDonorType === "sukarela";
    },
    isBtnSimpanDisabled() {
      return (
        !this.updateAdm.selectedDonorType ||
        (this.updateAdm.selectedDonorType === "pengganti" &&
          !(this.updateAdm.os || this.updateAdm.rs))
      );
    },
  },
  methods: {
    async getPendonor(id) {
      try {
        const { data } = await AktivasiPendonorService.getPendonor(id);
        const { data: pendonor } = data;
        const [currentDonor] = pendonor;
        this.currentDataPendonor = currentDonor;
        this.isQuestionaire = currentDonor.is_questionnaire;

        this.updateAdm = {
          id: this.currentDataPendonor.registration_id,
          selectedValidation: this.currentDataPendonor.validation,
          prev_history: this.currentDataPendonor.prev_history,
          last_donor_date: this.currentDataPendonor.last_donor_date,
          selectedDonorType: this.currentDataPendonor.donor_type,
          donor_times: this.currentDataPendonor.donor_times,
          os: this.currentDataPendonor.os,
          rs: this.currentDataPendonor.rs,
        };
      } catch (error) {
        console.error(error);
      }
    },
    async updateAdministration() {
      console.log("ke approved");
      console.log(this.updateAdm);
      try {
        this.isLoading = true;
        await AktivasiPendonorService.updateAdministration({
          id: this.currentDataPendonor.registration_id,
          validation: this.updateAdm.selectedValidation,
          prev_history: this.updateAdm.prev_history,
          last_donor_date: this.updateAdm.last_donor_date,
          donor_type: this.updateAdm.selectedDonorType,
          donor_times: this.updateAdm.donor_times,
          os: this.updateAdm.os,
          rs: this.updateAdm.rs,
        });
        this.$bvToast.toast("Pendonor Berhasil Diterima", {
          title: "Diterima",
          variant: "success",
          solid: true,
        });
        this.$router.push({ name: "donations" });
        this.acceptOrRejectionSuccess = true;
      } catch (err) {
        this.$bvToast.toast(
          "Terjadi Kesalahan Saat Melakukan Approved Pendonor",
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
    async rejectAdministration() {
      try {
        this.isLoading = true;
        await AktivasiPendonorService.rejectRegistration(
          this.currentDataPendonor.registration_id,
          this.updateAdm.rejectionReason,
          this.updateAdm.note
        );
        this.$bvToast.toast("Pendonor Berhasil Ditolak", {
          title: "Ditolak",
          variant: "success",
          solid: true,
        });
        this.acceptOrRejectionSuccess = true;
        this.$router.push({ name: "donations" });
      } catch (err) {
        this.$bvToast.toast("Terjadi Kesalahan Saat Melakukan Rejected", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    openConfirmationModal(type) {
      this.modalType = type;
      this.showModal = true;
    },

    async handleAction() {
      await (this.modalType === "accept"
        ? this.updateAdministration()
        : this.rejectAdministration());
      this.showModal = false;
      if (this.isEditMode) {
        this.isEditMode = false;
      }
    },

    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
      if (!this.isEditMode) {
        // Reset form data to original values when cancelling edit mode
        this.resetFormData();
      }
    },

    resetFormData() {
      // Reset form fields to the values from currentDataPendonor
      this.updateAdm = {
        id: this.currentDataPendonor.registration_id,
        selectedValidation: this.currentDataPendonor.validation,
        prev_history: this.currentDataPendonor.prev_history,
        last_donor_date: this.currentDataPendonor.last_donor_date,
        selectedDonorType: this.currentDataPendonor.donor_type,
        donor_times: this.currentDataPendonor.donor_times,
        os: this.currentDataPendonor.os,
        rs: this.currentDataPendonor.rs,
        rejectionReason: "lain-lain",
        note: "",
      };
    },
  },
};
</script>
